<script>
    import TheSlider from "@frontend/the-slider";
    import TheReviewsCard from "./components/TheReviewsCard";

    export default {
        name: "TheReviewsList",
        components: {
            TheSlider,
            TheReviewsCard,
        },
        props: {
            reviews: Array,
            breakpoints: Object,
            isPagination: {
                type: Boolean,
                default: true,
            },
            isArrow: {
                type: Boolean,
                default: false,
            },
            arrowPosition: {
                type: String,
                default: "bottom",
            },
            isBullets: Boolean,
        },
        data() {
            return {
                options: {
                    ...this.breakpoints,
                    watchSlidesVisibility: true,
                    slideVisibleClass: "visible",
                    lazy: {
                        loadOnTransitionStart: true,
                    },
                },
            };
        },
    };
</script>

<template>
    <div class="the-reviews-list">
        <the-slider
            nameSlider="review"
            :is-pagination="isPagination"
            :is-bullets="isBullets"
            :is-arrow="isArrow"
            :arrow-position="arrowPosition"
            :bullets-options="{ type: 'dot', dynamicBullets: true, clickable: false }"
            :options="options"
            :list="reviews"
        >
            <the-reviews-card
                slot="swiper-slide"
                class="swiper-slide"
                :review="review"
                v-for="review in reviews"
                :key="review.id"
            ></the-reviews-card>
        </the-slider>
    </div>
</template>

<style lang="scss">
    .the-reviews-list {
        width: calc(100% + 32px);
        margin-left: -16px;

        &:hover,
        &:focus-within {
            .c-slider__arrow {
                opacity: 1;
            }
        }

        @include bp($bp-mobile-sm) {
            width: 100%;
            margin: 0;
        }

        .c-slider__swiper {
            padding-left: 16px;

            @include bp($bp-mobile-sm) {
                padding-left: 0;
            }

            @include bp($bp-desktop-sm) {
                margin-bottom: 40px;
            }
        }

        .c-slider__scrollbar {
            width: calc(100% - 32px);
            margin-left: 16px;

            @include bp($bp-mobile-sm) {
                width: 100%;
                margin-left: 0;
            }
        }

        .c-slider__arrow {
            opacity: 0;
            transition: 0.3s ease-in-out all;
            display: none;

            @include bp($bp-desktop-sm) {
                display: flex;
                top: 159px;
            }

            @include bp($bp-desktop-sm-for-card) {
                top: 192px;
            }
        }

        .c-slider__arrow--next {
            right: 14px;
        }

        .c-slider__arrow--prev {
            left: 14px;
        }
    }
</style>
