<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "TheBrandsListCard",
        props: {
            brand: Object,
        },
        methods: {
            apiUrl,
        },
    };
</script>

<template>
    <a :href="brand.externalUrl" target="_blank" class="the-brands-list__item">
        <img class="swiper-lazy" :data-src="apiUrl(brand.previewImage)" alt="" />
    </a>
</template>

<style lang="scss">
    .the-brands-list__item {
        position: relative;
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include bp($bp-desktop-sm) {
            &:focus-visible {
                outline-offset: -2px;
            }
        }

        &:hover {
            img {
                @include bp($bp-desktop-sm) {
                    transform: translateZ(0) scale3d(1, 1, 1);
                }
            }
        }

        img {
            position: absolute;
            max-width: 100%;
            max-height: 100%;

            @include bp($bp-desktop-sm) {
                max-width: 180px;
                transform: translateZ(0) scale3d(0.97, 0.97, 0.97);
                transition: 0.2s ease-in transform;
            }
        }
    }
</style>
