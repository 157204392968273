<script>
    import { isMobile } from "@/utils";
    import TheSlider from "@frontend/the-slider";
    import TheBrandsListCard from "./components/TheBrandsListCard";

    export default {
        name: "TheBrandsList",
        components: {
            TheBrandsListCard,
            TheSlider,
        },
        props: {
            brandsList: Array,
            breakpoints: Object,
            isPagination: {
                type: Boolean,
                default: true,
            },
            isBullets: Boolean,
        },
        data() {
            return {
                options: {
                    lazy: {
                        loadOnTransitionStart: true,
                    },
                    ...this.breakpoints,
                    watchSlidesVisibility: true,
                    slideVisibleClass: "visible",
                },
                isMobileState: false,
            };
        },
        mounted() {
            this.isMobileState = this.isMobile(992);
        },
        methods: {
            isMobile,
        },
    };
</script>

<template>
    <div class="the-brands-list">
        <the-slider
            name-slider="brands"
            :number-slides="4"
            :is-pagination="isPagination"
            :is-arrow="!isMobileState"
            :is-bullets="isBullets"
            :bullets-options="{ type: 'dot', dynamicBullets: true, clickable: false }"
            :options="options"
            :list="brandsList"
        >
            <the-brands-list-card
                v-for="brand in brandsList"
                :key="brand.id"
                :brand="brand"
                slot="swiper-slide"
                class="swiper-slide"
            ></the-brands-list-card>
        </the-slider>
    </div>
</template>

<style lang="scss">
    .the-brands-list {
        @include bp($bp-desktop-sm) {
            .the-brands-list__item:not(.visible) {
                visibility: hidden;
                transition: visibility 0.5s ease-in;
            }

            .c-slider__swiper {
                padding: 0 30px;
            }

            .c-slider__arrow {
                background-color: transparent;

                svg {
                    fill: var(--slider-arrow-brands);
                }

                &.swiper-button-disabled {
                    opacity: 0.3 !important;
                }
            }

            .c-slider__arrow--prev {
                left: 0;
            }

            .c-slider__arrow--next {
                right: 0;
            }

            .brands-scrollbar {
                display: none;
            }
        }
    }
</style>
