<script>
    import { mapGetters } from "vuex";
    import { apiUrl } from "@/utils";

    export default {
        name: "HomeGridProducts",
        computed: {
            ...mapGetters("Catalog", ["mainSection", "secondSection"]),
        },
        methods: {
            calcNumberSection(num) {
                return this.mainSection[num];
            },
            apiUrl,
        },
    };
</script>

<template>
    <div class="grid-goods">
        <div class="grid-goods__half">
            <router-link
                :to="{
                    name: 'CatalogCategory',
                    params: {
                        slug: calcNumberSection(0).slug,
                    },
                }"
                class="grid-goods__item"
            >
                <div class="grid-goods__title">{{ calcNumberSection(0).name }}</div>
                <div class="grid-goods__link">Все категории</div>
                <div class="grid-goods__image">
                    <img :src="apiUrl(calcNumberSection(0).listPicture)" alt="" />
                </div>
            </router-link>
        </div>
        <div class="grid-goods__half grid-goods__half--options">
            <router-link
                :to="{
                    name: 'CatalogCategory',
                    params: {
                        slug: calcNumberSection(2).slug,
                    },
                }"
                class="grid-goods__item grid-goods__item--half"
            >
                <div class="grid-goods__title">{{ calcNumberSection(2).name }}</div>
                <div class="grid-goods__link">Все категории</div>
                <div class="grid-goods__image">
                    <img :src="apiUrl(calcNumberSection(2).listPicture)" alt="" />
                </div>
            </router-link>
            <div class="grid-goods__half">
                <router-link
                    :to="{
                        name: 'CatalogCategory',
                        params: {
                            slug: calcNumberSection(1).slug,
                        },
                    }"
                    class="grid-goods__item"
                >
                    <div class="grid-goods__title grid-goods__title--sm">
                        {{ calcNumberSection(1).name }}
                    </div>
                    <div class="grid-goods__link">Все категории</div>
                    <div class="grid-goods__image">
                        <img :src="apiUrl(calcNumberSection(1).listPicture)" alt="" />
                    </div>
                </router-link>
            </div>
            <div class="grid-goods__half">
                <router-link
                    :to="{
                        name: 'CatalogCategory',
                        params: {
                            slug: calcNumberSection(3).slug,
                        },
                    }"
                    class="grid-goods__item"
                >
                    <div class="grid-goods__title grid-goods__title--sm">
                        {{ calcNumberSection(3).name }}
                    </div>
                    <div class="grid-goods__link">Все категории</div>
                    <div class="grid-goods__image">
                        <img :src="apiUrl(calcNumberSection(3).listPicture)" alt="" />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .grid-goods {
        display: flex;
        flex-wrap: wrap;
    }

    .grid-goods__half {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include bp($bp-desktop-sm) {
            width: 50%;

            &:last-child {
                border-left: 1px solid var(--primary-border--light);
            }
        }
    }

    .grid-goods__item {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 16px;
        background-color: var(--neutral--light);
        margin-bottom: 8px;
        overflow: hidden;

        @include bp($bp-desktop-sm) {
            padding-top: 34px;
            margin-bottom: 0;
            &:focus-visible {
                outline-offset: -2px;
            }
        }

        &:hover {
            img {
                transform: scale(1.02);
            }
        }
    }

    .grid-goods__half--options {
        justify-content: space-between;

        .grid-goods__half {
            width: calc(50% - 4px);

            @include bp($bp-desktop-sm) {
                width: 50%;
            }
        }
    }

    .grid-goods__item--half {
        @include bp($bp-desktop-sm) {
            border-bottom: 1px solid var(--primary-border--light);
        }
    }

    .grid-goods__image {
        img {
            margin: 0 auto;
            transition: 0.2s ease-in-out all;
        }
    }

    .grid-goods__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 4px;
        color: var(--primary-text);

        @include bp($bp-desktop-sm) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .grid-goods__title--sm {
        font-size: 14px;
        line-height: 18px;

        @include bp($bp-desktop-sm) {
            font-size: 17px;
            line-height: 24px;
        }
    }

    .grid-goods__link {
        display: block;
        font-size: 13px;
        line-height: 17px;
        color: var(--primary);
        margin-bottom: 6px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 14px;
        }
    }
</style>
