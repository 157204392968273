<script>
    export default {
        name: "HomeAboutBrand",
    };
</script>

<template>
    <div class="about-brand">
        <div class="about-brand__half about-brand__half--text">
            <div class="about-brand__title">Travel Blue в России</div>
            <div class="about-brand__text">
                <p>
                    Всем привет! Мы команда магазина
                    <a target="_blank" href="https://madrobots.ru/about/">Madrobots</a> —
                    официальные представители Travel Blue в России. Уже 30 лет туристические
                    аксессуары бренда помогают путешественникам по всему миру.
                </p>
                <p>
                    Travel Blue — это знаменитый британский подход к комфорту! Мы знали, что в
                    нашей стране его оценят по достоинству. Продвижение туристических
                    аксессуаров стало одним из важных проектов Madrobots. Мы занимаемся
                    поддержкой покупателей в России — и сами с удовольствием берем в отпуск и
                    командировки сумки и подушки Travel Blue.
                </p>
            </div>
            <!--            <a href="" class="about-brand__btn btn btn&#45;&#45;yellow">Подробнее о бренде</a>-->
        </div>
        <div class="about-brand__half">
            <img src="/static/travel-blue/home/about-brand.jpg" alt="" />
        </div>
    </div>
</template>

<style lang="scss">
    .about-brand {
        background-color: #f7fcff;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include bp($bp-desktop-sm) {
            flex-wrap: nowrap;
        }
    }

    .about-brand__half {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .about-brand__half--text {
        padding: 32px 16px;

        @include bp($bp-desktop-sm) {
            padding: 56px 64px;
            max-width: 576px;
        }
    }

    .about-brand__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;
        color: var(--primary);

        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 24px;
        }
    }

    .about-brand__text {
        font-size: 15px;
        line-height: 24px;
        color: var(--secondary-text);
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 24px;
        }

        p {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        a {
            font-weight: 500;
            transition: 0.3s ease-in-out all;
            text-decoration: underline;
            color: var(--secondary-text);

            &:hover {
                color: #03a9f4;
            }
        }
    }

    .about-brand__btn {
        display: inline-block;
    }
</style>
