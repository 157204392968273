<script>
    import { mapState, mapActions } from "vuex";
    import {
        BRANDS_BREAKPOINT,
        MEDIA_BREAKPOINT,
        REVIEWS_BREAKPOINTS,
    } from "@tb/settings/breakpoints";
    import MainBanner from "@main/packages/MainBanner";
    import TheBrandsList from "@main/packages/TheBrandsList";
    import TheReviewsList from "@main/packages/TheReviewsList";
    import GuaranteeList from "@tb/components/GuaranteeList";
    import HomeGridProducts from "@tb/components/HomeGridProducts";
    import HomeRowProducts from "@tb/components/HomeRowProducts";
    import HomeRowBlog from "@tb/components/HomeRowBlog";
    import Newsletter from "@tb/components/Newsletter";
    import HomeHowBuy from "@tb/components/HomeHowBuy";
    import HomeAboutBrand from "@tb/components/HomeAboutBrand";
    import { metaInfo } from "@/core/MetaInfo";

    export default {
        name: "Home",
        components: {
            TheReviewsList,
            TheBrandsList,
            HomeHowBuy,
            HomeRowBlog,
            HomeRowProducts,
            HomeGridProducts,
            MainBanner,
            GuaranteeList,
            Newsletter,
            HomeAboutBrand,
        },
        data() {
            return {
                isLoadingCatalog: false,
                breakpointsForMedia: MEDIA_BREAKPOINT,
                breakpointsForReviews: REVIEWS_BREAKPOINTS,
                breakpointsForBrands: BRANDS_BREAKPOINT,
                reviewsInstagram: [],
                customizeProducts: [],
            };
        },
        computed: {
            ...mapState("Root", ["catalog", "allCatalog", "banners", "media", "brands"]),
            ...mapState("Blog", ["otherBlog"]),
        },
        serverPrefetch() {
            return Promise.all([
                this.getAllCatalog(
                    "&filters[showOnMainPage][eq]=1&filters[available][eq]=1&filters[eol][null]=1"
                ),
                this.getOtherBlog(),
            ]);
        },
        mounted() {
            if (!this.allCatalog.length) {
                this.getAllCatalog(
                    "&filters[showOnMainPage][eq]=1&filters[available][eq]=1&filters[eol][null]=1"
                );
            }
            // this.getCustomizeGoods();

            if (!this.banners.length) {
                this.getBanners();
                // сделать маппинг без (number,size< total) чисто отдает items
            }
            if (!this.otherBlog.length) {
                this.getOtherBlog();
            }
            if (!this.brands.length) {
                this.getBrands();
                // сделать маппинг без (number,size< total) чисто отдает items
            }

            if (!this.reviewsInstagram.length) {
                this.getReviewsList();
            }
            if (!this.customizeProducts.length) {
                this.getCustomizeGoods();
            }
        },
        methods: {
            ...mapActions("Root", ["getBanners", "getMedia", "getBrands", "getAllCatalog"]),
            ...mapActions("Blog", ["getOtherBlog"]),
            getReviewsList() {
                this.$store.optionalApi.getReviewsInstagram().then((response) => {
                    this.reviewsInstagram = response;
                });
            },
            getCustomizeGoods() {
                this.$store.productsApi
                    .getAllCatalog(
                        "&filters[showOnMainPageBottom][eq]=1&filters[available][eq]=1&filters[eol][null]=1"
                    )
                    .then((response) => {
                        this.customizeProducts = response.items.sort((a, b) => a.eol - b.eol);
                    });
            },
        },
        metaInfo() {
            return metaInfo.getMeta("home");
        },
    };
</script>

<template>
    <div class="p-home">
        <div class="l-container">
            <div class="p-home__banner">
                <main-banner
                    :banners="banners"
                    :is-bullets="true"
                    bullet-type="circle"
                ></main-banner>
            </div>

            <div id="catalog" class="p-home__grid">
                <home-grid-products></home-grid-products>
            </div>

            <div class="p-home__guarantee">
                <guarantee-list></guarantee-list>
            </div>

            <div class="p-home__row popular">
                <h2 class="p-home__title">Популярные товары</h2>
                <home-row-products
                    nameSlider="popular"
                    :goods-list="allCatalog"
                ></home-row-products>
            </div>

            <div v-if="otherBlog.length > 0" class="p-home__blog">
                <h2 class="p-home__title">Статьи и обзоры</h2>
                <home-row-blog :blog="otherBlog"></home-row-blog>
            </div>

            <div class="p-home__newsletter">
                <newsletter></newsletter>
            </div>

            <div v-if="customizeProducts.length > 0" class="p-home__row">
                <h2 class="p-home__title">
                    <!--                        {{ site.bottomProductsListName }}-->
                </h2>
                <home-row-products
                    nameSlider="customize"
                    :goods-list="customizeProducts"
                ></home-row-products>
            </div>

            <div class="p-home__how-buy">
                <h2 class="p-home__title">Где купить</h2>
                <home-how-buy></home-how-buy>
            </div>

            <template v-if="brands.length > 0">
                <div class="p-home__brands">
                    <the-brands-list
                        :brands-list="brands"
                        :breakpoints="breakpointsForBrands"
                        :is-pagination="false"
                        :is-bullets="true"
                    ></the-brands-list>
                </div>
            </template>

            <div v-if="reviewsInstagram.length > 0" class="p-home__review">
                <h2 class="p-home__title">Отзывы</h2>
                <the-reviews-list
                    :reviews="reviewsInstagram"
                    :breakpoints="breakpointsForReviews"
                    :is-pagination="false"
                    :is-bullets="true"
                ></the-reviews-list>
                <!--            <review-insta :reviews="reviews"></review-insta>-->
            </div>

            <div class="p-home__about">
                <home-about-brand></home-about-brand>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "~@tb/style/TheBrandsList.scss";

    .p-home__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 32px;
        }
    }

    .p-home__banner {
        margin: 0 -16px 24px;

        @include bp($bp-desktop-sm) {
            margin: 40px 0;
        }

        .c-slider__bottom-wrapper {
            margin-top: 10px;
        }
    }

    .p-home__grid {
        margin: 0 -16px 24px;

        @include bp($bp-desktop-sm) {
            margin: 0 auto 48px;
        }
    }

    .p-home__guarantee {
        margin-bottom: 40px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 48px;
        }
    }

    .p-home__row {
        margin-bottom: 40px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 56px;
        }
    }

    .p-home__newsletter {
        margin-bottom: 40px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 56px;
        }
    }

    .p-home__how-buy {
        margin-bottom: 16px;
    }

    .p-home__brands {
        margin-bottom: 40px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 112px;
        }
    }

    .p-home__review {
        margin-bottom: 40px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 72px;
        }
    }

    .p-home__about {
        margin: 0 -16px 56px;

        @include bp($bp-desktop-sm) {
            margin: 0 0 96px;
        }
    }
</style>
