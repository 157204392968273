<script>
    import { apiUrl } from "@/utils";
    import TheSvgIcon from "@frontend/the-svg-icon";

    export default {
        name: "TheReviewsCard",
        components: {
            TheSvgIcon,
        },
        props: {
            review: Object,
        },
        methods: {
            apiUrl,
        },
    };
</script>

<template>
    <a :href="review.link" target="_blank" class="the-reviews-card">
        <div class="the-reviews-card__header">
            <img
                class="the-reviews-card__avatar swiper-lazy"
                :data-src="apiUrl(review.photo)"
                alt=""
            />
            <div class="the-reviews-card__name">{{ review.author }}</div>
            <the-svg-icon name="cerf" :size-w="16"></the-svg-icon>
        </div>
        <div class="the-reviews-card__image">
            <img class="the-reviews-card__svg" src="/static/insta_color.svg" alt="" />
            <img
                class="the-reviews-card__img swiper-lazy"
                :data-src="apiUrl(review.picture)"
                alt=""
            />
        </div>
        <div class="the-reviews-card__desc" v-html="review.text"></div>
    </a>
</template>

<style lang="scss">
    .the-reviews-card {
        display: block;
        width: 178px;

        @include bp($bp-mobile-sm) {
            width: 100%;
        }

        @include bp($bp-desktop-sm) {
            &:not(.visible) {
                visibility: hidden;
                transition: visibility 0.5s ease-in;
            }
        }

        &:hover {
            .the-reviews-card__image {
                &:after {
                    opacity: 1;
                }

                .the-reviews-card__svg {
                    opacity: 1;
                }
            }
        }
    }

    .the-reviews-card__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .the-reviews-card__avatar {
        @include box(24px);
        object-fit: cover;
        border-radius: 50%;
    }

    .the-reviews-card__image {
        position: relative;
        margin-bottom: 8px;
        padding-top: 100%;
        background-color: #fff;
        border-radius: var(--border-lg);
        box-shadow: 0px 0px 10px #f2f2f5;

        @include bp($bp-desktop-sm) {
            box-shadow: 0px 0px 25px #f2f2f5;
        }

        &:after {
            content: "";
            @include center-absolute(0);
            top: 4px;
            left: 4px;
            @include box(calc(100% - 8px));
            background: rgba(255, 255, 255, 0.6);
            opacity: 0;
            transition: 0.3s ease all;
            border-radius: var(--border-lg);

            @include bp($bp-desktop-sm) {
                top: 8px;
                left: 8px;
                @include box(calc(100% - 16px));
            }
        }
    }

    .the-reviews-card__img {
        position: absolute;
        top: 4px;
        left: 4px;
        @include box(calc(100% - 8px));
        object-fit: cover;
        border-radius: var(--border-lg);

        @include bp($bp-desktop-sm) {
            top: 8px;
            left: 8px;
            @include box(calc(100% - 16px));
        }
    }

    .the-reviews-card__svg {
        opacity: 0;
        @include center-absolute(0);
        margin: auto;
        z-index: 1;
        transition: 0.3s ease-in-out opacity;
    }

    .the-reviews-card__name {
        font-size: 12px;
        line-height: 24px;
        font-weight: 600;
        margin-left: 4px;
        margin-right: 2px;
        color: var(--neutral-text);

        @include bp($bp-desktop-sm) {
            font-size: 16px;
            margin-left: 8px;
            margin-right: 6px;
        }
    }

    .the-reviews-card__desc {
        position: relative;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--neutral-text);
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 6px;
        max-height: 54px;
        overflow: hidden;
        padding: 0 4px;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 20px;
            max-height: 60px;
            padding: 0 8px;
        }

        &:after {
            content: "";
            position: absolute;
            width: 30%;
            height: 18px;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 1) 15%,
                rgba(255, 255, 255, 0) 100%
            );

            @include bp($bp-desktop-sm) {
                height: 20px;
            }
        }
    }
</style>
