<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "MainBannerItem",
        props: {
            banner: {
                type: Object,
                required: true,
            },
        },
        methods: {
            apiUrl,
            clickHandler(event) {
                if (this.banner.link && this.banner.link.includes("video")) {
                    event.preventDefault();
                    this.openVideoModal();
                } else {
                    this.scrollTo(event);
                }
            },
            openVideoModal() {
                const videoId = this.banner.link.replace("video=", "");
                this.$popup.show("modal-video", { videoId, autoplay: true });
            },
            scrollTo({ currentTarget }) {
                if (currentTarget.hash) {
                    let hash = currentTarget.hash.replace("#", "");
                    window.scrollTo({
                        top: document.querySelector(`.${hash}`).offsetTop - 100,
                        behavior: "smooth",
                    });
                }
            },
        },
    };
</script>

<template>
    <a :href="banner.link" class="c-main-banner__item swiper-slide" @click="clickHandler">
        <picture>
            <source media="(max-width: 991px)" :data-srcset="apiUrl(banner.srcMobile)" />
            <img class="swiper-lazy" :data-src="apiUrl(banner.src)" :alt="banner.name" />
        </picture>
    </a>
</template>

<style lang="scss">
    .c-main-banner__item {
        overflow: hidden;
        img {
            font-size: 0;
            color: transparent;
            margin: 0 auto;
            transition: 0.6s ease-in-out opacity;
            will-change: opacity;

            &:not(.swiper-lazy-loaded) {
                opacity: 0;
            }
        }

        &:focus-visible {
            outline-offset: -3px;
        }
    }
</style>
