<script>
    import Slider from "@main/packages/TheSlider";
    import { BLOG_BREAKPOINTS } from "@tb/settings/breakpoints";
    import BlogCard from "@tb/components/BlogCard";

    export default {
        name: "HomeRowBlog",
        components: {
            BlogCard,
            Slider,
        },
        props: {
            blog: Array,
        },
        data() {
            return {
                options: {
                    spaceBetween: 24,
                    ...BLOG_BREAKPOINTS,
                },
            };
        },
    };
</script>

<template>
    <div class="row-blog">
        <slider
            class="row-blog-slider"
            name-slider="blogHome"
            :is-pagination="false"
            :is-arrow="false"
            :options="options"
            :list="blog"
            :is-bullets="true"
            :bullets-options="{ type: 'dot', dynamicBullets: false }"
        >
            <blog-card
                slot="swiper-slide"
                class="swiper-slide"
                :blog="item"
                v-for="item in blog"
                :key="item.id"
            ></blog-card>
        </slider>
    </div>
</template>

<style lang="scss">
    .row-blog {
        margin-bottom: 24px;
    }
</style>
