var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-goods"},[_c('div',{staticClass:"grid-goods__half"},[_c('router-link',{staticClass:"grid-goods__item",attrs:{"to":{
                name: 'CatalogCategory',
                params: {
                    slug: _vm.calcNumberSection(0).slug,
                },
            }}},[_c('div',{staticClass:"grid-goods__title"},[_vm._v(_vm._s(_vm.calcNumberSection(0).name))]),_c('div',{staticClass:"grid-goods__link"},[_vm._v("Все категории")]),_c('div',{staticClass:"grid-goods__image"},[_c('img',{attrs:{"src":_vm.apiUrl(_vm.calcNumberSection(0).listPicture),"alt":""}})])])],1),_c('div',{staticClass:"grid-goods__half grid-goods__half--options"},[_c('router-link',{staticClass:"grid-goods__item grid-goods__item--half",attrs:{"to":{
                name: 'CatalogCategory',
                params: {
                    slug: _vm.calcNumberSection(2).slug,
                },
            }}},[_c('div',{staticClass:"grid-goods__title"},[_vm._v(_vm._s(_vm.calcNumberSection(2).name))]),_c('div',{staticClass:"grid-goods__link"},[_vm._v("Все категории")]),_c('div',{staticClass:"grid-goods__image"},[_c('img',{attrs:{"src":_vm.apiUrl(_vm.calcNumberSection(2).listPicture),"alt":""}})])]),_c('div',{staticClass:"grid-goods__half"},[_c('router-link',{staticClass:"grid-goods__item",attrs:{"to":{
                    name: 'CatalogCategory',
                    params: {
                        slug: _vm.calcNumberSection(1).slug,
                    },
                }}},[_c('div',{staticClass:"grid-goods__title grid-goods__title--sm"},[_vm._v(" "+_vm._s(_vm.calcNumberSection(1).name)+" ")]),_c('div',{staticClass:"grid-goods__link"},[_vm._v("Все категории")]),_c('div',{staticClass:"grid-goods__image"},[_c('img',{attrs:{"src":_vm.apiUrl(_vm.calcNumberSection(1).listPicture),"alt":""}})])])],1),_c('div',{staticClass:"grid-goods__half"},[_c('router-link',{staticClass:"grid-goods__item",attrs:{"to":{
                    name: 'CatalogCategory',
                    params: {
                        slug: _vm.calcNumberSection(3).slug,
                    },
                }}},[_c('div',{staticClass:"grid-goods__title grid-goods__title--sm"},[_vm._v(" "+_vm._s(_vm.calcNumberSection(3).name)+" ")]),_c('div',{staticClass:"grid-goods__link"},[_vm._v("Все категории")]),_c('div',{staticClass:"grid-goods__image"},[_c('img',{attrs:{"src":_vm.apiUrl(_vm.calcNumberSection(3).listPicture),"alt":""}})])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }