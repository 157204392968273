<script>
    import TheSlider from "@frontend/the-slider";
    import TheSkeleton from "@frontend/the-skeleton";
    import MainBannerItem from "./components/MainBannerItem";

    export default {
        name: "MainBanner",
        components: {
            TheSkeleton,
            TheSlider,
            MainBannerItem,
        },
        props: {
            banners: {
                type: Array,
                required: true,
            },
            isPagination: {
                type: Boolean,
                default: false,
            },
            isBullets: {
                type: Boolean,
                default: false,
            },
            bulletType: {
                type: String,
            },
        },
        data() {
            return {
                options: {
                    speed: 600,
                    lazy: {
                        loadOnTransitionStart: true,
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                        waitForTransition: false,
                    },
                    watchSlidesVisibility: true,
                    slideVisibleClass: "visible",
                    breakpoints: {
                        991: {
                            keyboard: {
                                enabled: true,
                            },
                        },
                    },
                },
            };
        },
        computed: {
            showBanners() {
                return this.banners.length > 0;
            },
            bulletsOptions() {
                return this.isBullets ? { type: this.bulletType, animated: true } : {};
            },
        },
    };
</script>

<template>
    <div class="c-main-banner">
        <the-slider
            v-if="showBanners"
            name-slider="banner"
            :is-pagination="isPagination"
            :is-bullets="isBullets"
            :bullets-options="bulletsOptions"
            :options="options"
            :list="banners"
            :events-emitting="true"
        >
            <main-banner-item
                v-for="banner in banners"
                :key="banner.id"
                :banner="banner"
                slot="swiper-slide"
            ></main-banner-item>
        </the-slider>
        <the-skeleton type="main-banner" v-else></the-skeleton>
    </div>
</template>

<style lang="scss">
    .c-main-banner {
        position: relative;
        padding-top: calc(75% + 20px);

        @include bp($bp-desktop-sm) {
            padding-top: 33.34%;

            .c-main-banner__item:not(.visible) {
                visibility: hidden;
                transition: visibility 0.5s ease-in;
            }
        }

        &:hover,
        &:focus-within {
            .c-slider__arrow {
                opacity: 1;
            }
        }

        .c-slider {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .c-slider__swiper {
            height: calc(100% - 20px);
            margin-bottom: 0;
            @include bp($bp-desktop-sm) {
                border-radius: var(--border-xl);
            }
        }

        .c-slider__scrollbar {
            margin-top: 18px;
        }

        .c-slider__arrow {
            opacity: 0;
            transition: 0.3s ease-in-out all;
            display: none;
            width: 44px;
            height: 44px;
            background-color: rgba(#000, 0.3);
            &:hover {
                background-color: rgba(#000, 0.5);
            }
            @include bp($bp-desktop-sm) {
                display: flex;
                top: 48%;
            }
        }

        .c-slider__arrow--next {
            right: 14px;
        }

        .c-slider__arrow--prev {
            left: 14px;
        }

        .c-slider__arrow {
            svg {
                fill: #fff;
            }
        }
    }
</style>
